import { createRoot } from "react-dom/client";

import "./theme.scss";
import { lazy } from "@remhealth/ui";
import { Suspense } from "@remhealth/core";

const App = lazy(() => import("./app"));

const root = createRoot(document.getElementById("root")!);
root.render(<Suspense minimal><App /></Suspense>);
